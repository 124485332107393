export default class RollUp  {
    constructor(selector) {
        try {
            this.element = document.querySelector(selector);
            this.buttons = this.element.querySelectorAll("[data-button-show]");
            this.rolls = Array.from(this.element.querySelectorAll("[data-block-show]"));
            this.init();
        } catch (error) {
            
        }
    }

    toggleBlock(button) { 
        button.classList.toggle('roll-btn-active');
        let id = button.getAttribute('data-button-show');
        let roll = this.rolls.find((element)=>{
            let idRoll = element.getAttribute('data-block-show');
            return  idRoll === id;
        })
        roll.classList.toggle('roll-active');
    }
    init() {
        this.buttons.forEach(button => {
            button.addEventListener('click', ()=>{
                this.toggleBlock(button)
            })
        });
    }


}