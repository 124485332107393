import noUiSlider from 'nouislider';
import wNumb from 'wnumb';


let filter = document.querySelectorAll('.form__range');
let uiSliderElements = [];

const formFilter = document.querySelector('.jsFormFilter');


if (formFilter) {
    formFilter.addEventListener('reset',(e)=>{
        let checkboxes = e.target.querySelectorAll('[type="checkbox"]');
        checkboxes.forEach((elem)=>{
            elem.checked = false
            elem.removeAttribute('checked');
        })
        uiSliderElements.forEach(slide => {
            slide.set([slide.options.range.min,slide.options.range.max]);
        })
        formFilter.submit();
    })
}




filter.forEach(element => {
    let min = element.parentElement.querySelector('input.min');
    let max = element.parentElement.querySelector('input.max');

    let slide = noUiSlider.create(element, {
        start: [min.value, max.value],
        connect: true,
        step: 0.01,
        range: {
            'min': Number(min.getAttribute('data-min')),
            'max': Number(max.getAttribute('data-max'))
        },
        format: wNumb({
            decimals: 2,
            mark: '.'
        })
    });

    let snapValues = [
        min,
        max
    ];
    
    slide.on('update', function (values, handle) {
        console.log(snapValues[handle].value);
        snapValues[handle].setAttribute('value', values[handle]);
    });
    uiSliderElements.push(slide);
});

