import { Swiper, Pagination, Navigation, Thumbs, Autoplay } from 'swiper';
import GLightbox from 'glightbox';
const lightbox = GLightbox();

Swiper.use([Pagination, Navigation, Thumbs, Autoplay]);

document.addEventListener("DOMContentLoaded", () => {
    let mainSliderTitle = document.querySelector('.main-slider .main-slider__slide-title');
    let mainSliderSubtitle = document.querySelector('.main-slider .main-slider__slide-subtitle');
    let mainSliderLink = document.querySelector('.main-slider .main-slider__btn');
    let main = new Swiper('.mainSliderJs', {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        centeredSlidesBounds: true,
        speed: 1000,
        // Автопрокрутка
        autoplay: {
          // Задержка
          delay: 3000,
          // Пауза при наведении курсора
          pauseOnMouseEnter: true,
        },
        noSwiping: 'swiper-no-swiping',
        on: {
          init: function () {
            mainSliderTitle.innerHTML = '';
            mainSliderTitle.innerHTML = this.slides[this.activeIndex].getAttribute('data-slide-name');
            mainSliderLink.setAttribute('href', this.slides[this.activeIndex].getAttribute('data-slide-link'));
            mainSliderSubtitle.innerHTML = '';
            mainSliderSubtitle.innerHTML = this.slides[this.activeIndex].getAttribute('data-slide-descr');
          },
        },
        breakpoints: {
          961: {
            slidesPerView: 2,
          },
        }
    })

    main.on('slideChange', function () {
      mainSliderTitle.innerHTML = '';
      mainSliderTitle.innerHTML = this.slides[this.activeIndex].getAttribute('data-slide-name');
      mainSliderSubtitle.innerHTML = '';
      mainSliderSubtitle.innerHTML = this.slides[this.activeIndex].getAttribute('data-slide-descr');
      mainSliderLink.setAttribute('href', this.slides[this.activeIndex].getAttribute('data-slide-link'));
    });


    let brands = new Swiper('.brands-slider', {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        loop: false,
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 0,
        breakpoints: {
          690: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }
    })
    let sliderCards = new Swiper('.sliderCardsJs', {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
          nextEl: '.slider-cards__next',
          prevEl: '.slider-cards__prev',
        },
        loop: true,
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 0,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }
    })

    let galleryThumbs = new Swiper('.sliderThumbsJs', {
      spaceBetween: 20,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    let galleryTop = new Swiper('.sliderTopJs', {
      thumbs: {
        swiper: galleryThumbs
      },
      navigation: {
        nextEl: '.slider-thumb-next',
        prevEl: '.slider-thumb-prev',
      },
    });


    let otherSlider = new Swiper('.otherJsSlider', {
      navigation: {
        nextEl: '.slider-cards__next',
        prevEl: '.slider-cards__prev',
      },
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 0,
      breakpoints: {
        690: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }
  })
})


