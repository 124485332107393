import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import Tabs from "./tabs";
import RollUp from "./rool_up";


new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      if (success) {

      }
    }
  });
  return false;
});

document.addEventListener('DOMContentLoaded', ()=>{
  let menuJs = document.querySelector('.menuJs');
  let hamburgerJs = document.querySelector('.jsHamburger');
  let headerMobileBlock = document.querySelector('.jsHeaderMobile');
  hamburgerJs.addEventListener('click', function(){
    this.classList.toggle('is-active');
    headerMobileBlock.classList.toggle('header__mobile-block--active');
  })
  menuJs.addEventListener('click',(e)=>{
    if (e.target.classList.contains('svg-icon') || e.target.tagName == 'use') {
        e.preventDefault();
        let block = e.target.closest('.menu__item').querySelector('.menu__inner-wrap');
        if(block.classList.contains('menu__inner-wrap--active')) {
          block.classList.remove('menu__inner-wrap--active');
        } else {
          block.classList.add("menu__inner-wrap--active")
        }
    }
  })


  let tabs = new Tabs('.tabsJs');
  let rols = new RollUp('.jsRollUp');
})