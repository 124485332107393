export default class Tabs  {
    constructor(selector) {
        try {
            this.element = document.querySelector(selector);
            this.buttons = this.element.querySelectorAll("[data-tab-btn]");
            this.tabs = Array.from(this.element.querySelectorAll("[data-tab-id]"));
            this.tabsMobile = Array.from(this.element.querySelectorAll("[data-tab-mobile-id]"));
            this.aciveTab = null;
            this.init();
        } catch (error) {
            
        }
    }

    hideTabs() {
        this.tabs.forEach(tab => {
            tab.classList.remove('tabs__item--active')
        })
        this.tabsMobile.forEach(tab => {
            tab.classList.remove('tabs__item--active')
        })
        this.buttons.forEach(button => {
            button.classList.remove('tabs__btn--active')
        })
    }
    showTab(button) { 
        
        let id = button.getAttribute('data-tab-btn');
        let tab = this.tabs.find((element)=>{
            let idTab = element.getAttribute('data-tab-id');
            return  idTab === id;
        })
        let tabMobile = this.tabsMobile.find((element)=>{
            let idTab = element.getAttribute('data-tab-mobile-id');
            return  idTab === id;
        })
        console.log(tabMobile.classList.contains('tabs__item--active'))
        console.log(tabMobile)
        console.log(id)

        if(this.aciveTab === id && tabMobile.classList.contains('tabs__item--active')) {
            this.hideTabs();
            return;
        }
        this.hideTabs();
        this.aciveTab = id;
        button.classList.add('tabs__btn--active');
        tab.classList.add('tabs__item--active');
        tabMobile.classList.add('tabs__item--active');
    }
    init() {
        this.buttons.forEach(button => {
            button.addEventListener('click', ()=>{
                this.showTab(button)
            })
        });
        console.log(this.buttons)
        console.log(this.tabs)
    }


}